/**
 * reportTypes.js
 *
 * Manage the report types and locations that can be used for PMAX reporting
 */

const inventoryReports = {
  // Met Life Stadium
  20450: ['F&B'],
  20451: ['F&B', 'Retail'],
  // Baltimore Orioles
  20670: ['F&B', 'Retail'],
  // Great American Ballpark
  20650: ['F&B', 'Retail'],
  // Miller Park
  20660: ['F&B'],
  // Wisconsin Expo
  20210: ['F&B'],
  // Key Bank Center
  20810: ['F&B'],
  // Buffalo Bills
  20470: ['F&B', 'Retail'],
  // Memphis Baseball
  20990: ['F&B', 'Retail'],
  // Red Bull Stadium
  20280: ['F&B', 'Retail'],
  // Providence Dunkin Donuts
  20260: ['F&B'],
  // Wildwoods
  20203: ['F&B'],
  // Blue Cross Arena
  20880: ['F&B'],
  // Boston University
  20240: ['F&B'],
  // St. Louis University
  20310: ['F&B'],
  // TD Garden
  20840: ['F&B', 'Retail', 'Accruals'],
  // Xfinity Center
  20250: ['F&B', 'Accruals'],
  // Chicago Football
  20480: ['Retail', 'Accruals'],
  // Green Bay FB
  20440: ['F&B', 'Accruals'],
  // Titletown
  20380: ['F&B', 'Accruals'],
  // Glendale BB
  20900: ['F&B', 'Retail', 'Accruals'],
  // Harrisburg BB
  20940: ['F&B', 'Accruals'],
  // Hickory BB
  20390: ['F&B', 'Accruals'],
  // Lakeland BB
  20960: ['F&B', 'Retail', 'Accruals'],
  // Maryvale BB
  20910: ['F&B', 'Accruals'],
  // Scottsdale BB
  20930: ['F&B', 'Accruals'],
  // Springfield BB
  20950: ['F&B', 'Retail', 'Accruals'],
  // Cleveland BB
  20690: ['F&B', 'Accruals'],
  // Minnesota BB
  20710: ['F&B', 'Retail', 'Accruals'],
  // San Diego BB
  20680: ['F&B', 'Retail', 'Accruals'],
  // Minnesota Soccer
  20740: ['F&B', 'Accruals'],
  // SunTrust
  20330: ['F&B'],
  // Kinston BB
  20350: ['F&B', 'Retail'],
  // Hockeytown
  20370: ['F&B'],
  // San Diego FB
  20490: ['F&B'],
  // Jax FB
  20550: ['F&B'],
  // Detroit BB
  20640: ['F&B', 'Retail'],
  // Chicago BB
  20720: ['F&B', 'Retail'],
  // The Roxy
  20730: ['F&B'],
  // Nashville
  20800: ['F&B', 'Retail'],
  // LCA
  20850: ['F&B'],
  // LA Forum
  20870: ['F&B'],

  // Calgary
  11020: ['F&B'],
  // Denver
  10300: ['F&B'],
  10301: ['F&B'],
  10304: ['F&B'],
  // Minneapolis
  10440: ['F&B'],
  10441: ['F&B'],
  10442: ['F&B'],
  10443: ['F&B'],
  // New Orleans
  10171: ['F&B'],
  10174: ['F&B'],
  // Ontario
  10344: ['F&B'],
  // Ottawa
  11010: ['F&B'],
  // Richmond
  10240: ['F&B', 'Retail'],
  // Charleston
  10250: ['F&B', 'Retail'],
  // Atlanta
  10481: ['F&B', 'Retail'],
  // Atlanta - Airport B
  10482: ['F&B', 'Retail'],
  // Boise
  10450: ['F&B'],
  // Tampa
  10491: ['F&B', 'Retail'],
  // Nashville
  10155: ['F&B', 'Retail'],
  // Austin Airport
  10361: ['F&B', 'Retail', 'Accruals'],
  10362: ['F&B', 'Retail', 'Accruals'],
  // Dallas Airport
  10460: ['F&B', 'Retail'],
  // Detroit Airport
  10401: ['F&B', 'Retail'],
  10402: ['F&B', 'Retail'],
  10406: ['F&B', 'Retail'],
  10408: ['F&B', 'Retail', 'Accruals'],
  10409: ['F&B', 'Retail', 'Accruals'],
  10410: ['F&B', 'Retail'],
  // Los Angeles
  10280: ['F&B', 'Retail'],
  // NYST (NYS Tolls)
  10600: ['F&B', 'Retail'],
  // Boston Logan
  10500: ['F&B', 'Retail'],
};

const revenueIntegrationReports = {
  // Ft. Lauderdale Airport
  10130: [
    'Travel Hospitality Cash Upload Form (EXCEL)',
    'Manual Adjustment Revenue Form- Rev Integ ONLY',
    'Support- Rev Integ ONLY',
  ],
  // Nashville Airport
  10155: [
    'Travel Hospitality Cash Upload Form (EXCEL)',
    'Manual Adjustment Revenue Form- Rev Integ ONLY',
    'Support- Rev Integ ONLY',
  ],
  // New Orleans Airport
  10170: [
    'Travel Hospitality Cash Upload Form (EXCEL)',
    'Manual Adjustment Revenue Form- Rev Integ ONLY',
    'Support- Rev Integ ONLY',
  ],
  // Oklahoma City Airport
  10190: [
    'Travel Hospitality Cash Upload Form (EXCEL)',
    'Manual Adjustment Revenue Form- Rev Integ ONLY',
    'Support- Rev Integ ONLY',
  ],
  // Richmond Airport
  10241: [
    'Travel Hospitality Cash Upload Form (EXCEL)',
    'Manual Adjustment Revenue Form- Rev Integ ONLY',
    'Support- Rev Integ ONLY',
  ],
  // Charleston Airport
  10250: [
    'Travel Hospitality Cash Upload Form (EXCEL)',
    'Manual Adjustment Revenue Form- Rev Integ ONLY',
    'Support- Rev Integ ONLY',
    'Starbucks Settlement Report',
  ],
  // Los Angeles LAX
  10280: [
    'Travel Hospitality Cash Upload Form (EXCEL)',
    'Manual Adjustment Revenue Form- Rev Integ ONLY',
    'Support- Rev Integ ONLY',
    'Service Charges by Workstation',
  ],
  // Denver Airport
  10300: [
    'Travel Hospitality Cash Upload Form (EXCEL)',
    'Manual Adjustment Revenue Form- Rev Integ ONLY',
    'Support- Rev Integ ONLY',
  ],
  // Buffalo Niagara Airport
  10320: [
    'Travel Hospitality Cash Upload Form (EXCEL)',
    'Manual Adjustment Revenue Form- Rev Integ ONLY',
    'Support- Rev Integ ONLY',
    'Netsuite Sales by Location by Class (EXCEL)',
    'Netsuite Cashier Report (EXCEL)',
    'Netsuite Tax Summary (EXCEL)',
  ],
  // Ontario Airport
  10340: [
    'Travel Hospitality Cash Upload Form (EXCEL)',
    'Manual Adjustment Revenue Form- Rev Integ ONLY',
    'Support- Rev Integ ONLY',
    'Shift4 Credit Card– Rev Integ ONLY (CSV)',
  ],
  // Austin Airport
  10360: [
    'Travel Hospitality Cash Upload Form (EXCEL)',
    'Manual Adjustment Revenue Form- Rev Integ ONLY',
    'Support- Rev Integ ONLY',
    'Netsuite Sales by Location by Class (EXCEL)',
    'Netsuite Cashier Report (EXCEL)',
    'Netsuite Tax Summary (EXCEL)',
  ],
  // Detroit Airport
  10400: [
    'Travel Hospitality Cash Upload Form (EXCEL)',
    'Manual Adjustment Revenue Form- Rev Integ ONLY',
    'Support- Rev Integ ONLY',
    'Netsuite Sales by Location by Class (EXCEL)',
    'Netsuite Cashier Report (EXCEL)',
    'Netsuite Tax Summary (EXCEL)',
  ],
  // Minneapolis MSP Airport
  10440: [
    'Travel Hospitality Cash Upload Form (EXCEL)',
    'Manual Adjustment Revenue Form- Rev Integ ONLY',
    'Support- Rev Integ ONLY',
    'Netsuite Sales by Location by Class (EXCEL)',
    'Netsuite Cashier Report (EXCEL)',
    'Netsuite Tax Summary (EXCEL)',
  ],
  // Boise Airport
  10450: [
    'Travel Hospitality Cash Upload Form (EXCEL)',
    'Manual Adjustment Revenue Form- Rev Integ ONLY',
    'Support- Rev Integ ONLY',
  ],
  // Dallas/ Ft. Worth Aiport
  10460: [
    'Travel Hospitality Cash Upload Form (EXCEL)',
    'Manual Adjustment Revenue Form- Rev Integ ONLY',
    'Support- Rev Integ ONLY',
  ],
  // Atlanta Airport
  10481: [
    'Travel Hospitality Cash Upload Form (EXCEL)',
    'Manual Adjustment Revenue Form- Rev Integ ONLY',
    'Support- Rev Integ ONLY',
  ],
  // ATL - Airport B
  10482: [
    'Travel Hospitality Cash Upload Form (EXCEL)',
    'Manual Adjustment Revenue Form- Rev Integ ONLY',
    'Support- Rev Integ ONLY',
  ],
  // Tampa Airport
  10490: [
    'Travel Hospitality Cash Upload Form (EXCEL)',
    'Manual Adjustment Revenue Form- Rev Integ ONLY',
    'Support- Rev Integ ONLY',
  ],
  // Boston Airport
  10500: [
    'Travel Hospitality Cash Upload Form (EXCEL)',
    'Manual Adjustment Revenue Form- Rev Integ ONLY',
    'Support- Rev Integ ONLY',
    'Netsuite Sales by Location by Class (EXCEL)',
    'Netsuite Cashier Report (EXCEL)',
    'Netsuite Tax Summary (EXCEL)',
  ],
  // Orlando International Airport
  10510: [
    'Travel Hospitality Cash Upload Form (EXCEL)',
    'Manual Adjustment Revenue Form- Rev Integ ONLY',
    'Support- Rev Integ ONLY',
  ],
  // Orlando International Airport
  10511: [
    'Travel Hospitality Cash Upload Form (EXCEL)',
    'Manual Adjustment Revenue Form- Rev Integ ONLY',
    'Support- Rev Integ ONLY',
  ],
  // NYS Thruway
  10600: [
    'Travel Hospitality Cash Upload Form (EXCEL)',
    'Manual Adjustment Revenue Form- Rev Integ ONLY',
    'Support- Rev Integ ONLY',
  ],
  // Syracuse Airport
  10711: [
    'Travel Hospitality Cash Upload Form (EXCEL)',
  ],
  // Baltimore Airport
  10721: [
    'Travel Hospitality Cash Upload Form (EXCEL)',
  ],
  // Tulsa Airport
  10731: [
    'Travel Hospitality Cash Upload Form (EXCEL)',
  ],
  // Tucson Airport
  10741: [
    'Travel Hospitality Cash Upload Form (EXCEL)',
  ],
  // Lexington Airport
  10751: [
    'Travel Hospitality Cash Upload Form (EXCEL)',
  ],
  // Midland Airport
  10761: [
    'Travel Hospitality Cash Upload Form (EXCEL)',
  ],
  // Ottawa Airport
  11010: [
    'Travel Hospitality Cash Upload Form (EXCEL)',
    'Manual Adjustment Revenue Form- Rev Integ ONLY',
    'Support- Rev Integ ONLY',
  ],
  // Calgary Airport
  11020: [
    'Travel Hospitality Cash Upload Form (EXCEL)',
    'Manual Adjustment Revenue Form- Rev Integ ONLY',
    'Support- Rev Integ ONLY',
  ],
  // Adelaide Int Airport
  12010: [
    'Australia Travel Hospitality Cash Upload Form (EXCEL)',
    'Sales Summary with Revenue Detail (EXCEL)',
    'Daily Banking Summary Report (EXCEL)',
    'Store Control (EXCEL)',
    'Store Product Sales (EXCEL)',
    'POS Media Log (EXCEL)',
  ],
  // Alice Springs Airport
  12020: [
    'Australia Travel Hospitality Cash Upload Form (EXCEL)',
    'Sales Summary with Revenue Detail (EXCEL)',
  ],
  // Sydney Central Rail
  12040: [
    'Australia Travel Hospitality Cash Upload Form (EXCEL)',
    'Sales Summary Revenue Detail (EXCEL)',
    'Revenue Report - Central Rail (EXCEL)',
  ],
  // Darwin Int Airport
  12050: [
    'Australia Travel Hospitality Cash Upload Form (EXCEL)',
    'Sales Summary with Revenue Detail (EXCEL)',
    'Daily Cash Report with Master Groups (EXCEL)',
    'Daily Banking Summary Report (EXCEL)',
  ],
  // Melbourne Int Airport
  12080: [
    'Australia Travel Hospitality Cash Upload Form (EXCEL)',
    'Sales Summary with Revenue Detail (EXCEL)',
    'Daily Banking Summary Report (EXCEL)',
    'Store Control Information - Oporto T1 (EXCEL)',
    'Store Control Information - Oporto T4 (EXCEL)',
  ],
  // Perth Int Airport
  12090: [
    'Australia Travel Hospitality Cash Upload Form (EXCEL)',
    'Sales Summary with Revenue Detail (EXCEL)',
    'Daily Banking Summary Report - Perth Virgin (EXCEL)',
    'Daily Banking Summary Report - Perth International Airport (EXCEL)',
    'Daily Operations Summary (EXCEL)',
    'Receipts (EXCEL)',
  ],
  // Sydney Int Airport
  12110: [
    'Australia Travel Hospitality Cash Upload Form (EXCEL)',
    'Daily Banking Summary Report (EXCEL)',
  ],
  // Wellington Int Airport
  12310: ['Travel Hospitality Cash Upload Form (EXCEL)', 'Sales Summary Revenue Detail (EXCEL)'],
  // Auckland Airport
  12320: [
    'Australia Travel Hospitality Cash Upload Form (EXCEL)',
    'Sales Summary with Revenue Detail (EXCEL)',
  ],
  // Glasgow Airport
  13020: [
    'Sales Summary Revenue Detail UK (Excel)',
    'UK Travel Cash Upload Form',
  ],
  // Heathrow Airport
  13030: [
    'Sales Summary Revenue Detail UK (Excel)',
    'UK Travel Cash Upload Form',
  ],
  // Luton Airport
  13070: [
    'Sales Summary Revenue Detail UK (Excel)',
    'UK Travel Cash Upload Form',
  ],
  // St Pancras Railway Station
  13080: [
    'Sales Summary Revenue Detail UK (Excel)',
    'UK Travel Cash Upload Form',
  ],
  // Euston Railway Station
  13090: [
    'Sales Summary Revenue Detail UK (Excel)',
    'UK Travel Cash Upload Form',
  ],
  // Excel Exhibition
  13100: [
    'Sales Summary Revenue Detail UK (Excel)',
    'UK Travel Cash Upload Form',
  ],
  // Chick Fil-A
  13200: [
    'Sales Summary Revenue Detail UK (Excel)',
    'UK Travel Cash Upload Form',
  ],
  // Wildwood Convention Center
  20200: ['Sportservice Cash Upload Form (EXCEL)'],
  // Boston University
  20240: ['Sportservice Cash Upload Form (EXCEL)'],
  // Xfinity Center
  20250: ['Sportservice Cash Upload Form (EXCEL)'],
  // Providence Arena
  20260: ['Sportservice Cash Upload Form (EXCEL)'],
  // Redbull Arena
  20280: ['Sportservice Cash Upload Form (EXCEL)'],
  // Atlanta Baseball
  20330: ['Sportservice Cash Upload Form (EXCEL)'],
  // Kinston Baseball
  20350: ['Sportservice Cash Upload Form (EXCEL)'],
  // St. Louis University
  20310: ['Sportservice Cash Upload Form (EXCEL)'],
  // Titletown SS
  20380: ['Sportservice Cash Upload Form (EXCEL)'],
  // Hickory BB
  20390: ['Sportservice Cash Upload Form (EXCEL)'],
  // Carolina Panthers Football
  20400: ['Shift4 Exports- Rev Integ ONLY (CSV)'],
  // Green Bay Football
  20440: ['Sportservice Cash Upload Form (EXCEL)'],
  // MetLife Stadium
  20450: ['Sportservice Cash Upload Form (EXCEL)', 'Shift4 Credit Card - Rev Integ ONLY (CSV)'],
  // Buffalo Football
  20470: ['Sportservice Cash Upload Form (EXCEL)'],
  // Chicago Football
  20480: ['Shift4 Exports- Rev Integ ONLY (CSV)', 'Sportservice Cash Upload Form (EXCEL)'],
  // Jacksonville Football
  20550: ['Sportservice Cash Upload Form (EXCEL)'],
  // St. Louis Baseball/ Busch Stadium
  20630: ['Shift4 Exports- Rev Integ ONLY (CSV)'],
  // St. Louis Cardinal Nation
  20631: ['Sportservice Cash Upload Form (EXCEL)'],
  // Detroit Baseball
  20640: [
    'Sportservice Cash Upload Form (EXCEL)',
    'Manual Adjustment Revenue Form- Rev Integ ONLY',
    'Support- Rev Integ ONLY',
    'Shift4 Exports- Rev Integ ONLY (CSV)',
  ],
  // Cincinati Baseball
  20650: ['Shift4 Exports- Rev Integ ONLY (CSV)'],
  // Milwaukee Baseball/Miller Park
  20660: ['Shift4 Exports- Rev Integ ONLY (CSV)'],
  // Baltimore Baseball
  20670: ['Shift4 Exports- Rev Integ ONLY (CSV)'],
  // Texas Baseball
  20701: ['Sportservice Cash Upload Form (EXCEL)'],
  // Minnesota Soccer / Allianz Field
  20712: ['Sportservice Cash Upload Form (EXCEL)'],
  // San Diego Baseball
  20680: ['Sportservice Cash Upload Form (EXCEL)'],
  // Cleveland Baseball
  20690: ['Sportservice Cash Upload Form (EXCEL)'],
  // Minnesota BB/ Target Field
  20710: ['Sportservice Cash Upload Form (EXCEL)'],
  // Chicago Baseball
  20720: ['Sportservice Cash Upload Form (EXCEL)'],
  // The Roxy
  20730: ['Sportservice Cash Upload Form (EXCEL)'],
  // Nashville Arena/ Bridgeston Arena
  20800: ['Sportservice Cash Upload Form (EXCEL)'],
  // Buffalo Arena/ KeyBank Center
  20810: ['Sportservice Cash Upload Form (EXCEL)'],
  // Columbus/ Nationwide Arena
  20820: ['Shift4 Exports- Rev Integ ONLY (CSV)'],
  // Tampa Bay Arena/ Amalie Arena
  20830: ['Sportservice Cash Upload Form (EXCEL)'],
  // TD Garden
  20840: ['Sportservice Cash Upload Form (EXCEL)'],
  // Detroit Arena/ Little Caesars Arena
  20850: ['Sportservice Cash Upload Form (EXCEL)'],
  // Blue Cross Arena
  20880: ['Sportservice Cash Upload Form (EXCEL)'],
  // Glendale Baseball
  20900: ['Sportservice Cash Upload Form (EXCEL)'],
  // Roger Dean Stadium
  20920: ['Sportservice Cash Upload Form (EXCEL)'],
  // Lakeland Baseball
  20960: ['Shift4 Credit Card - Rev Integ ONLY (CSV)'],
  // Memphis Baseball
  20990: ['Sportservice Cash Upload Form (EXCEL)'],
  // Etihad Stadium
  22010: [
    'Sportservice Cash Upload Form (EXCEL)',
    'TASK_Media_Totals (EXCEL)',
    'TASK_Splits_Report (EXCEL)',
  ],
  // Melbourne & Olympic Park
  22020: [
    'Sportservice Cash Upload Form (EXCEL)',
    'Sales Summary Revenue Detail (EXCEL)',
    'Revenue Report - Melbourne & Olympic Park (EXCEL)',
    'Respak (CSV)',
    'Discount Detail (EXCEL)',
  ],
  // Sydney Cricket Ground
  22030: [
    'Australia Sportservice Cash Upload Form (EXCEL)',
    'Sales Summary Revenue Detail (EXCEL)',
    'Cashier Report (EXCEL)',
    'Corporate EBMS Sales Report (EXCEL)',
    'Cash Receipts Journal (EXCEL)',
  ],
  // Melbourne Cricket Ground
  22150: [
    'Australia Sportservice Cash Upload Form (EXCEL)',
    'Sales Summary Revenue Detail (EXCEL)',
    'Cashier Report (EXCEL)',
    'Corporate EBMS Sales Report (EXCEL)',
    'Cash Receipts Journal (EXCEL)',
  ],
  // Collingwood Football Club
  22190: [
    'Big Commerce Online Sales Report (EXCEL)',
    'Australia Sportservice Cash Upload Form (EXCEL)',
    'Sales Summary Revenue Detail (EXCEL)',
    'Cashier Report (EXCEL)',
  ],
  // BSK / Blacktown International/BEST Lodge
  22161: [
    'Opera Daily Detail (EXCEL)',
    'Trial Balance (TEXT)',
    'Australia Sportservice Cash Upload Form (EXCEL)',
  ],
  // MSG Merchandise
  22170: [
    'Australia Sportservice Cash Upload Form (EXCEL)',
    'Sales Summary Revenue Detail (EXCEL)',
    'Cashier Report (EXCEL)',
    'Merchant Settlement Detail Report (CSV)',
    'Big Commerce Online Sales Report (EXCEL)',
    'XPR Sales - External 2 (EXCEL)',
    'XPR Sales - External 3 (EXCEL)',
    'XPR Sales - External 4 (EXCEL)',
    'XPR Sales - Shop (EXCEL)',
  ],
  // Royal Botanical Gardens
  22140: [
    'Australia Sportservice Cash Upload Form (EXCEL)',
    'Revenue Report - Royal Botanic (CSV)',
    'ResPak Tearoom (EXCEL)',
    'B-Point Report (CSV)',
    'Stripe Report (EXCEL)',
  ],
  // Soveriegn Hill
  22050: [
    'Australia Sportservice Cash Upload Form (EXCEL)',
    'Sales Summary Revenue Detail (EXCEL)',
    'Revenue Report - Sovereign Hill (EXCEL)',
  ],
  // Phillip Island (New)
  22131: [
    'Australia Sportservice Cash Upload Form (EXCEL)',
    'Revenue Report - Philip Island (EXCEL)',
  ],
  // Phillip Island (Old)
  22130: [
    'Australia Sportservice Cash Upload Form (EXCEL)',
    'Revenue Report - Philip Island (EXCEL)',
  ],
  // Westpac Stadium
  22320: [
    'Australia Sportservice Cash Upload Form (EXCEL)',
    'POS Data (EXCEL)',
    'EFTPOS Data (EXCEL)',
    'Account Charges (EXCEL)',
  ],
  // Emirates Stadium
  23010: [
    'UK SS Cash Upload Form',
    'Sales Summary with Revenue Detail UK (Excel)',
  ],
  // Wembley Stadium
  23030: [
    'UK SS Cash Upload Form',
    'Sales Summary with Revenue Detail UK (Excel)',
  ],
  // Fulham Stadium
  23050: [
    'UK SS Cash Upload Form',
    'Sales Summary with Revenue Detail UK (Excel)',
  ],
  // London Stadium
  23060: [
    'UK SS Cash Upload Form',
    'Sales Summary with Revenue Detail UK (Excel)',
  ],
  // Niagara Reservation State Park
  30100: [
    'Shift4 Credit Card- Rev Integ ONLY (CSV)',
    'Parks & Resorts Cash Upload Form (EXCEL)',
    'Revenue Center Financial Report - Concessions (EXCEL)',
    'Revenue Center Financial Report - Top of the Falls (EXCEL)',
    'Sales Mix Summary - Concessions (EXCEL)',
    'Sales Mix Summary - Top of the Falls (EXCEL)',
    'All Restaurant Sales Summary (EXCEL)',
    'Sales and Tender Reconciliation (EXCEL)',
    'Taxable Sales (EXCEL)',
    'Daily Sales by Class (EXCEL)',
    'Revenue Center Financial Report - All (EXCEL)',
    'NFSPPOSTTendersByOperator (EXCEL)',
    'NFSPPOSTransactionsByOperatorRe (EXCEL)',
    'Magensa- (EXCEL)',
    'TapIn2 - Product Type Summary (EXCEL)',
    'TapIn2 - Payment Type Summary (EXCEL)',
  ],
  // Geneva-on-the-Lake
  30140: [
    'Parks & Resorts Cash Upload Form (EXCEL)',
    'Daily Management Summary- Properties C (TEXT)',
    'Daily Management Summary- Properties O (TEXT)',
    'Daily Market/ADR Report (TEXT)',
    'Sales by Hour (EXCEL)',
    'Transactions Summary Report - Property O (TEXT)',
    'Summary by Category (EXCEL)',
    'Opera Financial Transactions by Generates (EXCEL) - L',
    'Opera Financial Transactions by Generates (EXCEL)- C',
  ],
  // Gideon Putnam
  30160: [
    'Shift4 Credit Card- Rev Integ ONLY (CSV)',
    'Parks & Resorts Cash Upload Form (EXCEL)',
    'Daily Management Summary Report (TEXT)',
    'Services by Hour (EXCEL)',
    'Financial Summary by Responsibility Center (EXCEL)',
    'Summary Activities Booked (EXCEL)',
    'Opera Daily Detail (EXCEL)',
    'Trial Balance (TEXT)',
    'Tax Liability Summary (EXCEL)',
    'Cashier Report (EXCEL)',
    'Netsuite GC Daily Item Sales by DCS (EXCEL)',
  ],
  // DSD-98, OU 30200.
  30200: ['Parks & Resorts Cash Upload Form (EXCEL)'],
  // Kennedy Space Center
  30210: ['Shift4 Credit Card- Rev Integ ONLY (CSV)', 'Parks & Resorts Cash Upload Form (EXCEL)'],
  // Squire Inn
  30260: [
    'Opera Daily Detail (EXCEL)',
    'Trial Balance (TEXT)',
    'Parks & Resorts Cash Upload Form (EXCEL)',
    'Cashier Report (EXCEL)',
    'Arcade Dashboard (EXCEL)',
    'Netsuite GC Daily Item Sales by DCS (EXCEL)',
    'Tax Liability Summary (EXCEL)',
    'IHG Settlement Report (EXCEL)',
  ],
  // Sequoia National Park
  30270: [
    'Shift4 Credit Card- Rev Integ ONLY (CSV)',
    'Parks & Resorts Cash Upload Form (EXCEL)',
    'Tax Summary (EXCEL)',
    'Sales by Location by Class (EXCEL)',
    'Cashier Report (EXCEL)',
    'Operations Detail - Wuksachi (EXCEL)',
    'Operations Detail- Lodgepole (EXCEL)',
    'Day Parts Report- Wuksachi (EXCEL)',
    'Day Parts Report- Lodgepole (EXCEL)',
    'General Ledger Report (TEXT)',
    'Transactions Summary - Properties E (TEXT)',
    'Transactions Summary- Properties Q (TEXT)',
    'Daily Management Summary- Properties E (TEXT)',
    'Daily Management Summary- Properties Q (TEXT)',
    'Services by Hour - PR Sequoia Peaks (EXCEL)',
    'Services by Hour - PR Sequoia Lodgepole (EXCEL)',
    'Opera Daily Detail - Property Q',
    'Trial Balance (TEXT) - Property Q',
  ],
  // Grand Canyon National Park
  30240: [
    'Shift4 Credit Card- Rev Integ ONLY (CSV)',
    'Parks & Resorts Cash Upload Form (EXCEL)',
    'Tax Liability Summary (EXCEL)',
    'Cashier Report (EXCEL)',
    'Daily Management Summary Report- Properties T (TEXT)',
    'Daily Management Summary Report- Properties Y (TEXT)',
    'Operations Detail - Yavapai Eatery (EXCEL)',
    'Operations Detail - Yavapai Tavern (EXCEL)',
    'Operations Detail - Yavapai Coffee Shop (EXCEL)',
    'Operations Detail - CVM Cafe (EXCEL)',
    'Operations Detail - Desert View TP (EXCEL)',
    'Operations Detail - General Store (EXCEL)',
    'Operations Detail - Tusayan Starbucks (EXCEL)',
    'Big Commerce Website (EXCEL)',
    'Netsuite GC Daily Item Sales by DCS (EXCEL)',
    'Services by Hour - PR GCNP Desert View Deli (EXCEL)',
    'Services by Hour - PR GCNP CVM Cafe - STAT (EXCEL)',
    'Services by Hour - PR GCNP Yavapai (EXCEL)',
    'Services by Hour - PR GCNP Desert View Trading Post (EXCEL)',
    'Operations Detail - Food Cart (EXCEL)',
    'Funds Movement Daily Report - First Data (EXCEL)',
    'Lottery (EXCEL)',
    'Trial Balance (TEXT) - Property T',
    'Trial Balance (TEXT) - Property Y',
    'Opera Daily Detail - Property T',
    'Opera Daily Detail - Property Y',
  ],
  // Westin Hotel
  30250: [
    'Parks & Resorts Cash Upload Form (EXCEL)',
    'Daily Revenue Report (EXCEL)',
    'Westin Fusebox Gateway (EXCEL)',
    'AR Summary Report (TEXT)',
    'Room Revenue Summary Report (EXCEL)',
    'TapIn2 - Product Type Summary (EXCEL)',
    'TapIn2 - Payment Type Summary (EXCEL)',
  ],
  // Peaks of Otter
  30280: [
    'Shift4 Credit Card- Rev Integ ONLY (CSV)',
    'Parks & Resorts Cash Upload Form (EXCEL)',
    'Operations Detail (EXCEL)',
    'Day Parts Report (EXCEL)',
    'Sales by Location by Class (EXCEL)',
    'Tax Summary (EXCEL)',
    'Cashier Report (EXCEL)',
    'Transactions Summary Report (TEXT)',
    'General Ledger Report (TEXT)',
    'Daily Management Summary (TEXT)',
    'Services by Hour (EXCEL)',
  ],
  // Kings Canyon National Park
  30290: [
    'Parks & Resorts Cash Upload Form (EXCEL)',
    'Tax Summary (EXCEL)',
    'Sales by Location by Class (EXCEL)',
    'Cashier Report (EXCEL)',
    'Day Parts Report - Grant Grove (EXCEL)',
    'Operations Detail - Grant Grove (EXCEL)',
    'Day Parts Report - Cedar Grove (EXCEL)',
    'Operations Detail - Cedar Grove (EXCEL)',
    'Daily Management Summary Report- Properties M (TEXT)',
    'Daily Management Summary- Properties I (TEXT)',
    'Services by Hour - PR KCNP Grant Grove (EXCEL)',
    'Services by Hour - PR KCNP Cedar Grove (EXCEL)',
    'POS Tender by Cashier (EXCEL)',
    'Opera Daily Detail - Property M',
    'Opera Daily Detail - Property I',
    'Trial Balance (TEXT) - Property M',
    'Trial Balance (TEXT) - Property I',
  ],
  // Kalaloch Ldg-National Park
  30310: [
    'Shift4 Credit Card- Rev Integ ONLY (CSV)',
    'Parks & Resorts Cash Upload Form (EXCEL)',
    'Standard Transactions Report (TEXT)',
    'Day Parts Report (EXCEL)',
    'Operations Detail (EXCEL)',
    'Daily Management Summary (TEXT)',
    'Sales by Hour (EXCEL)',
    'Opera Daily Detail',
    'Trial Balance (TEXT)',
  ],
  // Sea Crest Beach Hotel
  30350: [
    'Shift4 Credit Card- Rev Integ ONLY (CSV)',
    'Parks & Resorts Cash Upload Form (EXCEL)',
    'SMS Transaction Summary (EXCEL)',
    'General Ledger Report (TEXT)',
    'Daily Flash Report (TEXT)',
    'Services by Hour (EXCEL)',
  ],
  // Tenaya Lodge
  30480: [
    'Parks & Resorts Cash Upload Form (EXCEL)',
    'Hotel - Daily Management Summary Report (TEXT)',
    'Manual Adjustment Revenue Form- Rev Integ ONLY',
    'Support- Rev Integ ONLY',
    'Shift4 Exports- Rev Integ ONLY (CSV)',
    'Daily House Charges (EXCEL)',
    'Daily Management Summary- Properties NY (TEXT)',
    'Daily Management Summary- Properties T (TEXT)',
    'Spa Treatments Daily (EXCEL)',
    'Services by Hour (EXCEL)',
    'Daily Register Balance - Tenaya (EXCEL)',
    'Daily Sales Summary by Activity Date - Tenaya (EXCEL)',
    'Operations Detail - Harvest & Grounds (EXCEL)',
    'Operations Detail - Embers (EXCEL)',
    'Operations Detail - Sierra Room (EXCEL)',
    'Operations Detail - Jackalopes (EXCEL)',
    'Operations Detail - In Room Dining (EXCEL)',
    'Operations Detail - Timberloft (EXCEL)',
    'Operations Detail - Banquets (EXCEL)',
    'Labor Hours (EXCEL)',
    'Opera Daily Detail',
    'Trial Balance (TEXT)',
  ],
  // Shenandoah National Park
  30490: [
    'Shift4 Credit Card- Rev Integ ONLY (CSV)',
    'Parks & Resorts Cash Upload Form (EXCEL)',
    'Day Parts Report - Big Meadows Lodge (EXCEL)',
    'Operations Detail - Big Meadows Lodge (EXCEL)',
    'Day Parts Report - Big Meadows Wayside (EXCEL)',
    'Operations Detail - Big Meadows Wayside (EXCEL)',
    'Operations Detail - Elk Wallow (EXCEL)',
    'Operations Detail - Loft (EXCEL)',
    'Operations Detail - Skyland (EXCEL)',
    'Sales by Location by Class (EXCEL)',
    'Cashier Report (EXCEL)',
    'Tax Summary (EXCEL)',
    'Day Parts Report-Elk Wallow (EXCEL)',
    'Day Parts Report-Skyland (EXCEL)',
    'Day Parts Report-Loft (EXCEL)',
    'Transaction Summary- Properties B (TEXT)',
    'Transaction Summary- Properties L (TEXT)',
    'Transaction Summary- Properties S (TEXT)',
    'P&R Daily Sales by Class- Lewis (EXCEL)',
    'P&R Daily Sales by Class- Loft Mountain Wayside (EXCEL)',
    'P&R Daily Sales by Class- Big Meadows Wayside (EXCEL)',
    'P&R Daily Sales by Class- Loft Mountain Campstore (EXCEL)',
    'P&R Daily Sales by Class- Big Meadows Lodge (EXCEL)',
    'P&R Daily Sales by Class- Skyland (EXCEL)',
    'P&R Daily Sales by Class- Elk Wallow (EXCEL)',
    'General Ledger Report (TEXT)',
    'Daily Management Summary Report Properties: L (TEXT)',
    'Daily Management Summary Report Properties: S (TEXT)',
    'Daily Management Summary Report Properties: B (TEXT)',
    'Services by Hour - BML (EXCEL)',
    'Services by Hour - BMW (EXCEL)',
    'Services by Hour - Elk Wallow (EXCEL)',
    'Services by Hour - Skyland (EXCEL)',
    'Services by Hour - Loft (EXCEL)',
    'Opera Daily Detail - Properties B',
    'Opera Daily Detail - Properties L',
    'Opera Daily Detail - Properties S',
    'Trial Balance - Properties B (TEXT)',
    'Trial Balance - Properties L (TEXT)',
    'Trial Balance - Properties S (TEXT)',
  ],
  // Yellowstone National Park
  30500: [
    'Parks & Resorts Cash Upload Form (EXCEL)',
    'Sales by Location by Class (EXCEL)',
    'Cashier Report (EXCEL)',
    'Tax Summary (EXCEL)',
    'Operations Detail  - Canyon Village (EXCEL)',
    'Operations Detail  - Fishing Bridge (EXCEL)',
    'Operations Detail  - Grant Village (EXCEL)',
    'Operations Detail  - Lake Village (EXCEL)',
    'Operations Detail  - Old Faithful Lower (EXCEL)',
    'Services by Hour - PR YSNP S300 Old Faithful Lwr (EXCEL)',
    'Services by Hour - PR YSNP S302 Grant Village (EXCEL)',
    'Services by Hour - PR YSNP S304 Lake Village (EXCEL)',
    'Services by Hour - PR YSNP S306 Fishing Bridge (EXCEL)',
    'Services by Hour - PR YSNP S307 Canyon Village (EXCEL)',
    'Covers Data - Values (EXCEL)',
    'Square (Yellowstone General Stores)- (EXCEL)',
    'Services by Hour - PR YSNP Mammoth (EXCEL)',
    'Yahoo Credit Card Report (CSV)',
    'Braintree Settlement Report (CSV)',
    'TapIn2 - Product Type Summary (EXCEL)',
    'TapIn2 - Payment Type Summary (EXCEL)',
  ],
  // West Yellowstone Conf Hotel
  30510: [
    'Parks & Resorts Cash Upload Form (EXCEL)',
    'Operations Detail (EXCEL)',
    'Trial Balance (TEXT)',
    'Manager Report (TEXT)',
    'IHG Settlement Report (EXCEL)',
    'Services by Hour (EXCEL)',
  ],
  // Gray Wolf Lodge YS Park
  30520: [
    'Parks & Resorts Cash Upload Form (EXCEL)',
    'Hotel - Daily Management Summary Report (TEXT)',
    'Manual Adjustment Revenue Form- Rev Integ ONLY',
    'Support- Rev Integ ONLY',
    'Shift4 Exports- Rev Integ ONLY (CSV)',
    'Daily House Charges (EXCEL)',
    'Daily Management Summary- Properties NY (TEXT)',
    'Daily Management Summary- Properties T (TEXT)',
    'Spa Treatments Daily (EXCEL)',
    'Services by Hour (EXCEL)',
    'Daily Register Balance (EXCEL)',
    'Daily Sales Summary by Activity Date (EXCEL)',
    'Operations Detail - Harvest & Grounds (EXCEL)',
    'Operations Detail - Embers (EXCEL)',
    'Operations Detail - Sierra Room (EXCEL)',
    'Operations Detail - Jackalopes (EXCEL)',
    'Operations Detail - In Room Dining (EXCEL)',
    'Operations Detail - Timberloft (EXCEL)',
    'Operations Detail - Banquets (EXCEL)',
    'Labor Hours (EXCEL)',
    'Opera Daily Detail - EC',
    'Opera Daily Detail - GW',
    'Opera Daily Detail - YP',
    'Trial Balance - EC (TEXT)',
    'Trial Balance - GW (TEXT)',
    'Trial Balance - YP (TEXT)',
    'Manager Report (TEXT) - EC',
    'Manager Report (TEXT) - YP',
    'Manager Report (TEXT) - GW',
  ],
  // Whitefish Hotels
  30550: [
    'Parks & Resorts Cash Upload Form (EXCEL)',
    'Trial Balance (TEXT)',
    'Manager Flash (TEXT)',
  ],
  // Yellowstone Vacations
  30560: [
    'Parks & Resorts Cash Upload Form (EXCEL)',
    'YVT Boxed Lunches (EXCEL)',
    'Daily Sales Summary by Activity Date - Yellowstone (EXCEL)',
    'Daily Register Balance - Yellowstone (EXCEL)',
    'All Bookings in Category: Shuttle (EXCEL)',
    'All Bookings in Category: Snowcoach (EXCEL)',
    'All Bookings in Category: Snowmobile in the Park (EXCEL)',
    'All Bookings in Category: Snowmobile Rentals (EXCEL)',
    'All Bookings on Activity (EXCEL)',
    'Daily Sales Summary by Activity Date - Gardiner (EXCEL)',
    'Daily Register Balance - Gardiner (EXCEL)',
    'Daily Sales Summary by Activity Date - Two Top (EXCEL)',
    'Daily Register Balance - Two Top (EXCEL)',
    'Daily Balance Report - Yellowstone (Excel)',
    'Daily Balance Report - Two Top (Excel)',
    'Daily Balance Report - Gardiner (Excel)',
    'FareHarbor Sales - YVT (EXCEL)',
    'FareHarbor Sales - TT (EXCEL)',
    'FareHarbor Sales - GAR (EXCEL)',
    'FareHarbor Custom Fields Booking - YVT (EXCEL)',
    'FareHarbor Custom Fields Booking - TT (EXCEL)',
    'FareHarbor Custom Fields Booking - GAR (EXCEL)',
    'FareHarbor PayOuts - YVT (EXCEL)',
    'FareHarbor PayOuts - TT (EXCEL)',
    'FareHarbor PayOuts - GAR (EXCEL)',
    'FareHarbor Custom Fields Customer - YVT (EXCEL)',
    'FareHarbor Custom Fields Customer - TT (EXCEL)',
    'FareHarbor Custom Fields Customer - GAR (EXCEL)',
  ],
  // Honey Creek Resort
  30570: [
    'Parks & Resorts Cash Upload Form (EXCEL)',
    'Department Revenue Report (EXCEL)',
    'RevOcc by Guest Type (EXCEL)',
    'Daily Sales Summary by Activity Date - Honey Creek (EXCEL)',
    'Daily Register Balance - Honey Creek (EXCEL)',
    'Transaction Report (TEXT)',
    'Daily Flash Report (TEXT)',
  ],
  // Estes Park
  30580: [
    'Parks & Resorts Cash Upload Form (EXCEL)',
    'ERP Cashier Report (EXCEL)',
    'Sales by Location by Class (EXCEL)',
    'Tax Results (EXCEL)',
    'Day Parts Report (EXCEL)',
    'Operations Detail (EXCEL)',
    'Trial Balance (TEXT)',
    'Manager Report (TEXT)',
    'Journal by Cashier and Transaction Code (EXCEL)',
    'IHG Settlement Report (EXCEL)',
    'Services by Hour (EXCEL)',
  ],
  // Trendz
  30581: [
    'Cashier Report (EXCEL)',
  ],
  // Gardiner
  30600: [
    'Reasons for Stay Report (TEXT)',
    'Trial Balance (TEXT)',
    'Parks & Resorts Cash Upload Form (EXCEL)',
  ],
  // El Questro Wilderness Park
  32010: [
    'Australia Parks & Resorts Cash Upload Form (EXCEL)',
    'Sales Summary Revenue Detail (EXCEL)',
    'Opera Report - EEG (CSV)',
    'Opera Report - EHM (CSV)',
    'Opera Report - EST (CSV)',
  ],
  // Kings Canyon Resort
  32030: [
    'Australia Parks & Resorts Cash Upload Form (EXCEL)',
    'Sales Summary Revenue Detail (EXCEL)',
    'Opera Report (CSV)',
  ],
  // Lizard Island Resort
  32040: [
    'Australia Parks & Resorts Cash Upload Form (EXCEL)',
    'Sales Summary Revenue Detail (EXCEL)',
    'Opera Report (CSV)',
  ],
  // Lane Cove River Tourist Park
  32080: [
    'Australia Parks & Resorts Cash Upload Form (EXCEL)',
    'RezExpert (CSV)',
    'Daily Audit Summary (EXCEL)',
  ],
  // Pinewood Lodge
  32300: [
    'Australia Parks & Resorts Cash Upload Form (EXCEL)',
    'Sales Summary Revenue Detail (EXCEL)',
    'Income Summary By Date (EXCEL)',
    'Ledger Movement & D Report (EXCEL)',
    'Daily Audit Summary (EXCEL)',
    'Opera Daily Detail (EXCEL)',
    'Trial Balance (TEXT)',
  ],
  // American Greyhound
  40100: [
    'Gaming & Entertainment Cash Upload Form (EXCEL)',
    'Perfecta Output - AGP Daily Sales (EXCEL)',
    'Perfecta Output - OTB Settlement (EXCEL)',
    'Perfecta Output - Statistics (EXCEL)',
  ],
  // Daytona Beach Racing and Card Club
  40110: [
    'Gaming & Entertainment Cash Upload Form (EXCEL)',
    'Perfecta Journal (EXCEL)',
    'Gaming & Entertainment Statistic Upload Form (EXCEL)',
  ],
  // Wheeling Island Hotel Casino
  40180: [
    'Gaming & Entertainment Cash Upload Form (EXCEL)',
    'Financial Detail Report for Slots (EXCEL)',
    'Tickets Issued Report (EXCEL)',
    'Jackpot Slip Report (TEXT)',
    'WV Gross Terminal Report (EXCEL)',
    'Perfecta Daily Journal (EXCEL)',
    'Financial Detail Report for Electronic Roulette and Craps (EXCEL)',
    'Manual Adjustment Revenue Form- Rev Integ ONLY',
    'Support- Rev Integ ONLY',
    'Shift4 Credit Card- Rev Integ ONLY (CSV)',
    'Daily Management Summary Report (TEXT)',
    'Report Stiff Sheet by game-location-shift (EXCEL)',
    'Gaming & Entertainment Statistic Upload Form (EXCEL)',
    'Coin In Report (EXCEL)',
    'Jackpot Summary Report (EXCEL)',
    'Rev / Occ by Guest Type (EXCEL)',
    'Rev / Occ by Tariff (EXCEL)',
    'Department Revenue Report (EXCEL)',
    'FRACCCSV Report (CSV)',
    'FRUNRSUM Report (CSV)',
    'FRUNVOUSCS Report (CSV)',
    'Soft Count Master Gaming Summary Report (EXCEL)',
    'Statistical Net Win (EXCEL)',
    'Statistical Net Win - ETG (EXCEL)',
    'Expired Voucher - STAT (EXCEL)',
    'ETG Flash Report (EXCEL)',
  ],
  // Finger Lakes Casino
  40120: [
    'Gaming & Entertainment Cash Upload Form (EXCEL)',
    'Daily Remittance to NYS Gaming Commission (EXCEL)',
    'Net Win Variance (EXCEL)',
    'Cash Drawer Summary Report (EXCEL)',
    'Ditronics Check Detail (EXCEL)',
    'Cash Advance Transactions - Ditronics (EXCEL)',
    'Gaming & Entertainment Statistic Upload Form (EXCEL)',
  ],
  // Hamburg Gaming
  40130: [
    'Gaming & Entertainment Cash Upload Form (EXCEL)',
    'Daily Remittance to NYS Gaming Commission (EXCEL)',
    'Coupon Redemption by Promo (EXCEL)',
    'Net Win Variance (EXCEL)',
    'Cash Drawer Summary Report (EXCEL)',
    'Cash Advance Transactions - Ditronics (EXCEL)',
    'Check Cashing Ditronics (EXCEL)',
    'Progressive Contribution and JP Wins Report (EXCEL)',
    'Tax and Setoff Summary by Customer Name (EXCEL)',
    'Progressive Jackpot History Report (EXCEL)',
    'Gaming & Entertainment Statistic Upload Form (EXCEL)',
  ],
  // Southland Gaming and Racing
  40150: [
    'Gaming & Entertainment Cash Upload Form (EXCEL)',
    'Statistical Net Win (EXCEL)',
    'Soft Count Master Gaming Summary Report (EXCEL)',
    'BAT Group Balance (TEXT)',
    'Pools Analysis (EXCEL)',
    'BAT Track Liability (TEXT)',
    'BAT Terminal Sales Detail (TEXT)',
    'State Check (EXCEL)',
    'Expired Voucher - STAT (EXCEL)',
    'Performance Summary - STAT (EXCEL)',
    'Gaming & Entertainment Statistic Upload Form (EXCEL)',
    'FRACCCSV Report (CSV)',
    'FRUNRSUM Report (CSV)',
    'FRUNVOUSCS Report (CSV)',
    'Closing Balance Details Report (EXCEL)',
    'Post Scan Report (Excel)',
    'RA - House Account Detail - Comps (EXCEL)',
  ],
  // Mardi Gras Casino and Resort
  40170: [
    'Gaming & Entertainment Cash Upload Form (EXCEL)',
    'Financial Tournament Report (EXCEL)',
    'WV Gross Terminal Report (EXCEL)',
    'Soft Count Master Gaming Summary Report (EXCEL)',
    'Transaction Report (EXCEL)',
    'Daily Outs Balance Report (TEXT)',
    'Reconciliation Report (TEXT)',
    'ITW Settlement Report (TEXT)',
    'Gaming & Entertainment Statistic Upload Form (EXCEL)',
    'Perfecta Daily Journal (EXCEL)',
    'Department Revenue Report (EXCEL)',
    'FRACCCSV Report (CSV)',
    'FRUNVOUSCS Report (CSV)',
  ],
  // Orange City Racing and Card Club
  40190: [
    'Gaming & Entertainment Cash Upload Form (EXCEL)',
    'Perfecta Journal (EXCEL)',
    'Gaming & Entertainment Statistic Upload Form (EXCEL)',
  ],
  // Jakes 58 Hotel/ Food & Beverage
  40200: [
    'Gaming & Entertainment Cash Upload Form (EXCEL)',
    'Department Revenue Report (EXCEL)',
    'Rev/Occ by Guest Type (EXCEL)',
    'Gaming and Entertainment Statistic Upload Form (EXCEL)',
  ],
  // Jake's 58 Casino
  40202: [
    'Gaming & Entertainment Cash Upload Form (EXCEL)',
    'Daily Remittance to NYS Gaming Commission (EXCEL)',
    'Net Win Variance (EXCEL)',
    'Cash Drawer Summary Report (EXCEL)',
    'Ditronics ATM (EXCEL)',
    'Ditronics Cash Advance (EXCEL)',
    'Ditronics Check Cashing (EXCEL)',
    'Stat Type by Player (EXCEL)',
    'Gaming & Entertainment Statistic Upload Form (EXCEL)',
  ],
  // Shafston Hotel
  42020: [
    'Gaming & Entertainment Cash Upload Form (EXCEL)',
    'Sales Summary Revenue Detail (EXCEL)',
  ],
  // Aspley Hotel
  42030: [
    'Gaming & Entertainment Cash Upload Form (EXCEL)',
    'Sales Summary Revenue Detail (EXCEL)',
  ],
  // Darwin Casino Resort
  42051: [
    'Keno Report (TEXT)',
    'Opera Trial Balance (TEXT)',
    'EGM Meter Report (EXCEL)',
    'Table Games Win Report (EXCEL)',
    'Roulette Bet Report (EXCEL)',
    'Gaming & Entertainment Cash Upload Form (EXCEL)',
  ],
  // Boston Billiard Club & Casino
  40280: [
    'Gaming & Entertainment Cash Upload Form (EXCEL)',
    'Financial Tournament Report (EXCEL)',
    'WV Gross Terminal Report (EXCEL)',
    'Soft Count Master Gaming Summary Report (EXCEL)',
    'Transaction Report (EXCEL)',
    'Daily Outs Balance Report (TEXT)',
    'Reconciliation Report (TEXT)',
    'ITW Settlement Report (TEXT)',
    'Gaming & Entertainment Statistic Upload Form (EXCEL)',
    'Perfecta Daily Journal (EXCEL)',
    'Department Revenue Report (EXCEL)',
    'FRACCCSV Report (CSV)',
    'FRUNVOUSCS Report (CSV)',
    'AGT History Pool Summary (EXCEL)',
    'Castle Hill Pool Liability Report (EXCEL)',
    'Castle Hill Pool Roll Up Report (EXCEL)',
    'Exacta Pool Change Summary (EXCEL)',
    'Exacta Pool Status Summary (EXCEL)',
    'Table Games Interblock Revenue Report (EXCEL)',
    'Table Games Pitboss Master Gaming Summary Report (EXCEL)',
    'Table Games Poker Drop Detail Report (EXCEL)',
  ],
  // Sheraton Nashua
  40281: [
    'Gaming & Entertainment Cash Upload Form (EXCEL)',
    'Perfecta Journal (EXCEL)',
    'Gaming & Entertainment Statistic Upload Form (EXCEL)',
    'AR Summary Report (TEXT)',
    'Daily Revenue Report (TEXT)',
  ],
  // Garden Corp TD Garden- Admin
  50000: [
    'Event Revenue Report (EXCEL)',
    'North Station Weekly Parking Revenue Report (EXCEL)',
    'TD Garden Cash Upload Form (EXCEL)',
    'Event Revenue Report Adjustments (EXCEL)',
    'Paymentech Report (EXCEL)',
    'Givex Report (EXCEL)',
    'Archtics Sales Summary',
  ],
  // Boston Garden
  50030: ['Archtics (EXCEL)'],
  // Boston Bruins
  50100: [
    'Boston ProShop Cash Upload Form (EXCEL)',
    'Paymentech Report (EXCEL)',
  ],
  // DNC Admin
  90000: [
    'Freedompay Gateway- (EXCEL)',
    'MerchantLink Gateway- (EXCEL)',
    'Worldpay Vantiv Gateway-(EXCEL)',
    'SSUS_Project_Activity- (EXCEL)',
    'Gateway- (EXCEL)',
    'Braintree- (EXCEL)',
    'Bridgepay- (EXCEL)',
    'CardConnect (Bypass)- (EXCEL)',
    'Cybersource- (EXCEL)',
    'Exadigm- (EXCEL)',
    'Firstdata- (EXCEL)',
    'Fusebox- (EXCEL)',
    'Shift4- (EXCEL)',
    'SPS Enrollment (IHG Merlin)- (EXCEL)',
    'Square- (EXCEL)',
    'Authorize.Net- (EXCEL)',
    'Yahoo- (EXCEL)',
    'Magensa (EXCEL)',
    'WindCave KSC Gateway- (CSV)',
    'Worldpay - Pazien Gateway (Excel)',
    'Freedom Pay UK Gateway (Excel)',
    'Elavon Gateway (Excel)',
    'Grab Gateway (CSV)',
    'Marriott Freedom Pay Gateway',
  ],
};

const orderCashReports = [
  'Sales Order',
  'Non-Standard Contract',
  'Contract Updates (Major)',
  'Contract Updates (Minor)',
  'Pricing List',
  // 'Operating Unit AR Aging',
  // 'Subsidiary AR Aging',
  // 'Corporate AR Aging',
  'Payment Remittance',
  'Bad Debt Reserves',
  'Pari-mutuel Settlement',
  'Advance Deposit',
];

const reportingReports = [
  'AR Reports',
  'AP Reports',
  'FA Reports',
  'GL Reports',
  'Asset Management (subledger reports only)',
  'Project Costing (subledger reports only)',
  'Accounts Payable (subledger reports only)',
  'Accounts Receivable (subledger reports only)',
];

const journalReports = ['Journal Backup'];

/**
 * Get the available report types by report and operating unit id.
 *
 * @param {*} reportKey key of current report (passed from url)
 * @param {*} ouid OperatingId of location to retrieve reports
 */
export const getReportTypes = (reportKey, ouid) => {
  switch (reportKey) {
    case 'revenue-integration': {
      // DATA-4151 - Map certain report types to ALL locations
      const globalRevenueIntegrationReportTypes = [
        'Daily Transaction Summary',
      ];
      if (ouid === 'All') return globalRevenueIntegrationReportTypes;
      if (revenueIntegrationReports[ouid]) {
        return [...revenueIntegrationReports[ouid],
          ...globalRevenueIntegrationReportTypes];
      }
      break;
    }
    case 'order-to-cash': {
      return orderCashReports;
    }
    case 'reporting': {
      return reportingReports;
    }
    case 'blackline-journal': {
      return journalReports;
    }
    case 'inventory': {
      if (ouid === 'All') return ['Missing Item', 'F&B', 'Retail', 'Accruals'];
      if (inventoryReports[ouid]) return [...inventoryReports[ouid], 'Missing Item'];
      break;
    }
    default:
      break;
  }
  return [];
};

/**
 * Current locations approved for reporting. Currently includes Wave 1 locations.
 */
export const reportLocations = [
  'ada', // Adelaide Int Airport
  'alz', // Allianz Field
  'ats', // Atlanta BB
  'azf', // American Greyhound
  'api', // Phillip Island
  'asa', // Alice Springs Airport
  'ash', // Soveriegn Hill
  'asp', // Aspley Hotel
  'atl', // Atlanta Airport
  'aua', // Auckland Airport
  'aur', // Austin Airport
  'bcc', // Boston Billiard Club & Casino
  'boi', // Boise Airport
  'bos', // Boston Airport
  'bsk', // Blacktown International Sportspark
  'bss', // Buffalo Arena/ Keybank Center
  'buf', // Buffalo Niagara Airport
  'bwi', // Baltimore Airport
  'cal', // San Diego Baseball
  'ccs', // Charleston Airport
  'cgo', // Chicago FB
  'clg', // Mardi Gras Casino and Resort
  'cls', // Columbus/ Nationwide Arena
  'cns', // Cincinnati Baseball
  'cob', // The Roxy
  'cvl', // Cleveland BB
  'daa', // Darwin Int Airport
  'dag', // Gardiner at Yellowstone
  'dbk', // Daytona Beach Racing and Card Club
  'dds', // Detroit Arena/ Little Caesars Arena
  'den', // Denver Airport
  'dfw', // Dallas/ Ft. Worth Airport
  'dnp', // Niagara Reservation State Park
  'dpt', // Tenaya lodge
  'dts', // Detroit Baseball
  'dtw', // Detroit Airport
  'elq', // El Questro Wilderness Park
  'etm', // Etihad Stadium
  'ess', // Buffalo FB
  'fal', // Sea Crest Beach Hotel
  'fll', // Ft. Lauderdale Airport
  'fra', // Finger Lakes Casino
  'gbr', // Hamburg Gaming
  'gbs', // Greenbay FB
  'gcs', // Squire Inn
  'gct', // DNC Geneva Canopy Tours
  'gds', // Wildwoood Convention Center
  'gem', // GEM
  'gln', // Glendale Baseball
  'gsp', // geneva on the lake
  'grc', // Grand Canyon National Park
  'gsc', // Geneva on the Lake Cottages
  'gph', // Gideon Putnam
  'gwl', // Gray Wolf Lodge YS Park
  'has', // Boston U
  'hck', // Honey Creek
  'hcs', // Hickory BB
  'iss', // Chicago Field
  'jak', // Jake's 58 Hotel
  'jkc', // Jake's 58 Casino
  'wvr', // Orange City Racing and Card Club
  'jss', // Jacksonville Football
  'jum', // Jumers
  'kal', // Kalaloch Ldg-National Park
  'kcr', // Kings Canyon Resort
  'knc', // Kings Canyon Park
  'ksc', // Kenedy Space Station
  'kss', // Kinston BB
  'lax', // Los Angeles LAX Airport
  'lex', // Lexington Airport
  'lco', // Lane Cove River Tourist Park
  'lks', // Lakeland Baseball
  'lir', // Lizard Island Resort
  'mbr', // Darwin Casino
  'mco', // Orlando International Airport
  'met', // Texas BB
  'mid', // Midland Airport
  'mla', // Melbourne Int Airport
  'mld', // Baltimore Baseball
  'mns', // Minnesota BB
  'msa', // TD Garden
  'mop', // Melbourne & Olympic Park
  'msp', // Minneapolis MSP Aiport
  'mss', // Memphis Baseball
  'msy', // New Orleans Airport
  'mva', // Miami Valley
  'mws', // Milwaukee Baseball/ Miller Park
  'nbg', // Boston TD Garden
  'nen', // Xfinity center
  'nml', // MetLife Stadium
  'npl', // Pinewood Lodge
  'nsh', // Nashville Airport
  'nyt', // NYS Thruway
  'nwp', // Westpac Stadium
  'okc', // Oklahoma City Airport
  'ont', // Ontario Airport
  'pea', // Pert Int Airport
  'pot', // Peaks of Otter
  'pvd', // Providence Arena
  'rbs', // Red Bull Arena
  'ric', // Richmond Airport
  'rky', // Ridgeline Hotel - estes parl
  'rsi', // Bluecross Arena
  'rss', // Ruby Seven Studios
  'scg', // Sydney Cricket Ground
  'scs', // Sydney Central Rail
  'shf', // Shafston Hotel
  'seq', // Sequoia National Park
  'sfb', // Carolina Panthers Football
  'shd', // Shenandoah National Park
  'slu', // St. Louis University
  'smm', // Nasville Arena/ Bridgestone Arena
  'sra', // Southland Gaming and Racing
  'stl', // St. Louis Baseball/ Busch Stadium
  'tam', // Tampa Bay Arena/ Amalie Arena
  'tts', // Titletown SS
  'shn', // Sheraton Nashua
  'ssu', //  Roger Dean
  'sya', // Sydney Int Airport
  'syr', // Syracuse Airport
  'ter', // Royal Botanical Gardens
  'tpa', // Tampa Airport
  'trz', // Trendz
  'tul', // Tulsa Airport
  'tus', // Tucson Airport
  'wem', // Wembley
  'wea', // Wellington Int Airport
  'wes', // Westin Hotel
  'wig', // Wheeling
  'wpl', // Whitefish Lodge
  'wyl', // West Yellowstone Conf Hotel
  'yad', // Yellowstone Vacations
  'yel', // Yellowstone National Park
  'yow', // Ottawa Airport
  'yyc', // Calgary Airport
  'snp', // Mining Locations
  'bld',
  'dar',
  'mtc',
  'tdv',
  'mav',
  'swt',
  'cav',
  'jun',
  'lvl',
  'nrv',
  'bam',
  'wec',
  'mwv',
  'mdv',
  'dav',
  'spn',
  'cbg',
  'vvl',
  'pck',
  'mcg', // Melbourne Cricket Ground
  'cfc', // Collingwood Football Club
  'gla', // UK Locations
  'lha',
  'lta',
  'sps',
  'eus',
  'exc',
  'ora',
  'ems',
  'wem',
  'fcc',
  'lls',
  '10000',
  '12000',
  '20000',
  '22000',
  '30000',
  '30020',
  '30021',
  '32000',
  '40000',
  '40240',
  '40250',
  '40500',
  '42000',
  '42010',
  '50000',
  '50120',
  '50130',
  '50140',
  '60000',
  '70000',
  '90000',
  '90200',
  '92010',
  '92020',
  '92070',
  '92320',
  '92300',
  '22100',
  '92000',
  '93001',
  '93010',
  '93020',
  // Patina locations
  'att', // Market Café AT&T Center
  'bbg', // Patina Events at Brooklyn Botanic Garden
  'bre', // Brasserie 8.5
  'cae', // California Endowment
  'cat', // Catal Restaurant
  'caw', // C+M Westwood
  'chc', // Concert Hall Cafe
  'cki', // Colorado Kitchen
  'cst', // Chef Street at Macys Herald Square
  'des', // Descanso Gardens
  'edi', // The Edison
  'gti', // The Grand Tier
  'kit', // The Kitchen for Exploring Foods
  'kpm', // Market Café 550
  'lam', // LA Music Center
  'lin', // Lincoln Ristorante
  'mar', // Maria & Enzo’s Ristorante
  'naf', // Naples 45 Ristorante E Bar
  'nap', // Naples Ristorante E Bar
  'nic', // Nick & Stefs
  'nor', // Norton Simon
  'nss', // Nick & Stefs Steakhouse
  'pan', // Panevino Ristorante
  'pcp', // Café Pinot
  'pmm', // Morimoto Asia
  'ppp', // Market Café @ Park Place
  'ptf', // Patina 250
  'ray', // Ray's & Stark Bar
  'rcc', // Rock Center Cafe
  'sta', // State Grill and Bar
  'tan', // Tangata
  'tjo', // Tortilla Jo's
  'viv', // Vivoli il Gelato
];
